@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
  text-align: center;
  color: black;
  font-size: 14;
  font-family: 'Raleway', serif;
}

.appHeader_menuContainer {
  min-width: 100%;
}

.ant-menu-dark.ant-menu-horizontal {
  flex: 1;
  /* background: white; */

}

.ant-menu,
.ant-layout-header {
  background: black;
  color: white;
  justify-content: flex-end;
  font-size: 14;
  font-family: Raleway;
}

/* .ant-menu,
.ant-layout-header:hover {
  background: black;
  color: white;
  justify-content: flex-end;
  font-size: 14;
  font-family: Raleway;
} */

.ant-menu-overflow {
  justify-content: flex-end;
}

.ant-menu-item-active {
  color: rgb(0, 228, 0) !important;
  transform: scale(1.1);
  /* font-weight: bold; */
}

.ant-layout-content {
  background-color: #ffffffa8;
}

.social {
  display: flex;
  flex-direction: row;
  width: 100px;
  justify-content: space-evenly;
}

.social > a {
  color: white;
}

.background {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  background-image: url('https://scontent.fmex5-1.fna.fbcdn.net/v/t39.30808-6/406423058_860818139165849_2760609570734791924_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=WPMqVmZ4zn0Ab69v_Ht&_nc_ht=scontent.fmex5-1.fna&oh=00_AfAIfkonm2K8RcXegeMUCAQU7H1Th9Cf09RJU2-PHudZVg&oe=661D555C');
  background-size: cover;
  background-position: center 60em;
  filter: blur(0px); /* Adjust the blur amount as needed */
  /* overflow: hidden; */
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1; /* Ensure the title is above the blurred background */
}

.title > h1 {
  text-align: center;
  padding: 20px;
  font-size: 30px;
  font-style: italic;
  color: white;
  font-weight: bolder;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.5);
  background-color: rgba(0,0,0,0.7);
  border-radius: 10px;
  /* color: green; */
}

.about-content {
  margin-top: 0;
  width: 90%;
  margin: auto;
  margin-bottom: 5vh;
  padding: 0;
  border-radius: 20;
}

.about-content-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.about-content-div-div {
  padding: 10;
  flex: 1 1 0px;
  display: flex;
  justify-content: center;
}


.rutas-content {
  margin-top: 0;
  width: 100%;
  padding: 0;
  /* height: 100vh; */
  /* max-height: 800px; */
}

.rutas-content > div {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 10;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  /* max-height: 1000px; */
  /* height: 100vh; */
  background-color: black;
}


.ant-form-item-row {
  display: flex !important;
  /* width: 90vw; */
  margin: auto;
  /* max-width: 900px; */
}

.ant-form-item-label {
  /* color: white !important; */
}

.radio-products {
  /* position: relative; */
  /* display: flex; */
  /* width: 100vw; */
  margin: auto;
  /* border: 1px solid blue; */
  /* flex-direction: column; */
  /* justify-content: space-around; */
  /* align-items: center; */
  /* justify-content: space-around; */
}

.radio-item {
  margin: 20px;
  /* width: 400px; */
}

.tabs-container {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #ffffffa8;
}

.tabs {
  width: 90%;
  max-width: 1500px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.tabs > div {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  flex: 1 1 0px;
}

.tab {
width: 100px;
}

.tabi {
  /* border: 1px solid red */
}

.tabi > div {
  /* border: 1px solid yellow */
}

b {
  font-size: 14px;

}


/* Estilos generales */
.footer {
  background-color: #000;
  color: #fff;
  padding: 54px;
  text-align: center;
}

.footer .social-links {
  margin-bottom: 16px;
}

.footer .social-links a {
  margin-right: 8px;
}

.footer .links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.footer .links a {
  color: #fff;
  margin-right: 16px;
  margin-bottom: 8px;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .footer .links {
    justify-content: center;
  }

  .footer .links a {
    margin-right: 8px;
    margin-bottom: 16px;
  }
}


.timeline-container {
  padding: 20px;
  margin: 20px
}


.formInscripcion {
  padding: 10px 0 80px;
}

h1, h2,h3,h4,h5 {
  font-family: "Raleway";
}


.one {
  margin: 5vh auto;
  min-height: 90vh;
  /* background-color: red; */
  max-width: 1000px;
  width: 90%;
}


.two {
  margin: 5vh auto;
  min-height: 90vh;
  /* background-color: white; */
  padding: 50px 10px;
  border-radius: 20px;
  max-width: 1300px;
  width: 90%;
}

.huellita-text{
  margin-top: 5vh;
  margin-bottom: 5vh;
}


.subtitle {
  color: green;
  margin-top: 40px;
}

.subtitle-o {
  color: orange;
  margin-top: 40px;
}

.subtitle-one {
  color: green;
  margin-top: 20px;
  margin-bottom: 40px;
}

.footer-pages{
  color: orange;
  font-size: 16px;
  text-decoration: none;
}

.footer-pages:hover{
  color: green;
  /* font-size: 16px; */
}


.ant-image-preview-operations{
  background-color: black;
}

.bg-black {
  background-color: black;
  color: white; /* Set the text color to white for contrast */
}

.nav-link{
  color: white !important;
}

.navbar-collapse{
  flex-grow: 0 !important;
}

.navbar-toggler-icon {
  color: white;
}

/* CustomNavbar.css */
.navbar-toggler.custom-toggle::before {
  content: '\f055'; /* Unicode for the FontAwesome icon you want */
  font-family: 'Font Awesome 5 Free'; /* Font family for FontAwesome */
}

.trigger{
  color: white;
}

p {
  font-size: 1rem;
}

video {
  border-radius: 10px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .about-content-div{
    display: flex;
    flex-direction: column;
  }
  .history-item{
    display: flex !important;
    flex-direction: column !important;
  }

  .time-header, .time-circle, .time-info {
    padding: 0px 0px !important;
  }
  .time-info {
    margin: 20px 0px;
  }

  .history{
    padding: 0px !important;
  }

  .ant-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-col{
    margin: 5px 0;
    max-width: 100%;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}